import { db } from '@/config/firebase';


async function getClassificationsByDispatchId(id) {
    try {
        const parentRef = db.collection('dispatches').doc(id);
        const classificationsRef = parentRef.collection('classifications');
        const snapshot = await classificationsRef.get();

        const classifications = {};
        snapshot.forEach((doc) => {
            const data = doc.data();
            classifications[data.id] = data;
        });

        return classifications;
    } catch (error) {
        console.error('Error getting change requests:', error);
        return [];
    }
}

async function getClassificationByAssetType(params) {
    try {
        const parentRef = db.collection('dispatches').doc(params.id);
        const classificationsRef = parentRef.collection('classifications');
        const snapshot = await classificationsRef
            .where('assetTypeId', '==', params.assetTypeId)
            .where('dispatchId', '==', params.dispatchId)
            .get();

        let classification = {};
        snapshot.forEach((doc) => {
            classification = doc.data();
        });

        return classification;
    } catch (error) {
        console.error('Error getting change requests:', error);
        return null;
    }
}

async function getClassifications(filter) {
    let classificationsObj = {};

    let query = db.collectionGroup('classifications');

    if (filter.dispatchId && filter.dispatchId.length > 0) {
        query = query.where('dispatchId', '==', filter.dispatchId);
    } else if (filter.dispatchIds && filter.dispatchIds.length > 0) {
        query = query.where('dispatchId', 'in', filter.dispatchIds);
    }

    if (filter.assetTypeId && filter.assetTypeId.length > 0) {
        query = query.where('assetTypeId', '==', filter.assetTypeId);
    }

    const querySnapshot = await query.get();
    querySnapshot.forEach(doc => {
        if (doc.exists) {
            let classification = doc.data();
            classification.id = doc.id;
            classificationsObj[classification.id] = classification;
        }
    });

    return classificationsObj;
}

export default {
    getClassificationsByDispatchId,
    getClassificationByAssetType,
    getClassifications,
}